import React, { useEffect, useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSelect from '../../component/inputSelect/InputSelect';
import OrderTable from './OrderTable';
import { constant } from '../../utils/constant';
import { vendor_list } from '../../store/action/vendor';
import {
	create_purchase_order,
	get_delivery_methods,
	get_purchase_order_number,
} from '../../store/action/purchaseOrder';
import { toast } from 'react-toastify';

const CreatePurchaseOrder = () => {
	const todayDate = new Date().toISOString().split('T')[0];
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isErrortable, setIsTableError] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [disabled, setDisabled] = useState(id ? true : false);
	const [TableError, setTableError] = useState(false);
	const [vendorList, setVendorList] = useState([]);
	const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
	const [selectedVendorId, setSelectedVendorId] = useState(null);
	const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(null);
	const [deliveryMethods, setDeliveryMethods] = useState([]);
	const [referenceNumber, setReferenceNumber] = useState(null);
	const [deliveryAddress, setDeliveryAddress] = useState(null);
	const [selectedDeliveryMethodId, setSelectedDeliveryMethodId] = useState(null);

	const navigateRouter = (path) => {
		navigate(path);
	};

	useEffect(() => {
		const param = `pageSize=${constant.bigNumber}`;

		dispatch(vendor_list(param))
			.then((res) => {
				if (res?.success) {
					if (res?.data) {
						if (res?.data?.vendors && res?.data?.vendors?.length > 0) {
							setVendorList(res?.data.vendors);
						}
					}
				} else {
					setVendorList([]);
				}
			})
			.catch((err) => {
				setVendorList([]);
			});

		dispatch(get_purchase_order_number())
			.then((res) => {
				if (res?.success) {
					setPurchaseOrderNumber(res?.purchase_order_number);
				}
			})
			.catch((err) => {
				console.log('err', err);
			});

		dispatch(get_delivery_methods())
			.then((res) => {
				if (res?.success) {
					console.log('check res', res?.delivery_methods);
					setDeliveryMethods(res?.delivery_methods);
				}
			})
			.catch((err) => {
				console.log('err', err);
			});
	}, []);

	const handleSavePurchaseOrder = () => {
		setDisabled(true);

		const data = {
			vendor_id: selectedVendorId,
			purchase_order_number: purchaseOrderNumber, // required
			date: todayDate, // required
			delivery_address: deliveryAddress,
			expected_delivery_date: expectedDeliveryDate,
			remarks: referenceNumber,
			payment_terms: '50',
			delivery_method: selectedDeliveryMethodId,
			is_draft: 0, // 1 is draft , 0 is not draft,
			purchase_order_products: tableData.map((order) => ({
				product_id: order.product_id,
				product_packaging: order.packaging,
				order_quantity: Number(order.qty),
			})),
		};

		dispatch(create_purchase_order(data))
			.then((res) => {
				if (res?.success) {
					toast.success('Purchase Order Created Successfully');
					setDisabled(false);
				}
			})
			.catch((err) => {
				console.log('err', err);
				setDisabled(false);
			});
	};

	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>Create New Purchase Order</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save"
								onClick={() => handleSavePurchaseOrder()}
							/>
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save & Send"
								// onClick={() => setDisabled(false)}
							/>
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save as Draft"
								// onClick={() => setDisabled(false)}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/purchases-order`)}
							/>
						</div>
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col-6">
							<InputSelect
								options={vendorList.map((vendor) => ({
									key: vendor.vendor_id,
									value: vendor.vendor_name,
								}))}
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								value={selectedVendorId}
								onChange={(value) => setSelectedVendorId(value)}
								// disabled={disabled}
								// error={error?.vendor_name}
								autoFocus={true}
							/>
						</div>
						<div className="col">
							<InputField
								label="Order Number #"
								placeholder="Order Number"
								type="text"
								required={true}
								value={purchaseOrderNumber}
								onChange={(value) => setPurchaseOrderNumber(value)}
								disabled={disabled}
								// error={error?.vendor_display_name}
							/>
						</div>
						<div className="col">
							<InputField
								readOnly={true}
								label="Date"
								placeholder="Enter Date"
								type="text"
								value={todayDate}
								required={true}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<InputField
								label="Delivery Address"
								placeholder="Delivery Address"
								type="text"
								required={true}
								value={deliveryAddress}
								onChange={(value) => setDeliveryAddress(value)}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>

						<div className="col">
							<InputField
								label="Expected delivery Date"
								placeholder="expected delivery  Date"
								type="date"
								value={expectedDeliveryDate}
								onChange={(val) => setExpectedDeliveryDate(val)}
								// disabled={disabled}
								required={true}
								// error={error?.date}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Reference #"
								placeholder="Reference #"
								type="text"
								value={referenceNumber}
								onChange={(value) => setReferenceNumber(value)}
								// required={true}
								// disabled={disabled}
								// error={error?.email}
							/>
						</div>
						<div className="col">
							<InputSelect
								options={deliveryMethods.map((method) => ({
									key: method.delivery_methods_id,
									value: method.method_name,
								}))}
								label="Delivery Methods"
								placeholder="Delivery Methods"
								returnInputValue={true}
								// required={true}
								value={selectedDeliveryMethodId}
								onChange={(value) => setSelectedDeliveryMethodId(value)}
								// disabled={disabled}
								// error={error?.vendor_name}
							/>
						</div>
					</div>
				</div>
				{/* order Table */}
				<OrderTable
					tableData={tableData}
					setTableData={setTableData}
					disabled={disabled}
					paramId={id}
					tableError={TableError}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
		</div>
	);
};

export default CreatePurchaseOrder;
