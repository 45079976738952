import React, { useRef, useState, useEffect } from 'react';

import './addContactPerson.scss';
import { ContactSolutation } from '../../../utils/dropsdown';
import InputField from '../../../component/form/FormInput';
import InputSelect from '../../../component/inputSelect/InputSelect';
import Button from '../../../component/ui/Button';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateVendorContactPerson, vendor_contact_create } from '../../../store/action/vendor';
import { toast } from 'react-toastify';
const AddContactPerson = ({
	selectedContactPersonIndex,
	tableData,
	setTableData,
	onClose,
	reloadTable,
	vendorId,
}) => {
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(); // Add state for disabling inputs
	const [error, setError] = useState({});
	const [formData, setFormData] = useState({
		salutation: '',
		first_name: '',
		last_name: '',
		email: '',
		work_phone: '',
		mobile: '',
		department: '',
	});

	useEffect(() => {
		if (selectedContactPersonIndex !== null) {
			const contactPerson = tableData[selectedContactPersonIndex];

			const newFormData = {
				salutation: contactPerson?.salutation || '',
				first_name: contactPerson?.first_name || '',
				last_name: contactPerson?.last_name || '',
				email: contactPerson?.email || '',
				work_phone: contactPerson?.work_phone || '',
				mobile: contactPerson?.mobile || '',
				department: contactPerson?.department || '',
			};

			setFormData(newFormData);

			console.log('hello', contactPerson);
		}
	}, [selectedContactPersonIndex]);

	console.log('setFormData', formData);

	const handleInputChange = (key, value) => {
		const updatedData = { ...formData, [key]: value };
		setFormData(updatedData);

		if (value) {
			delete error?.[key];
		}
		// console.log()
	};
	const addContactPerson = () => {
		if (vendorId) {
			dispatch(vendor_contact_create(formData, vendorId)).then((res) => {
				if (res?.success) {
					toast.success(res?.message);
					onClose();
					reloadTable();
				}
			});
		} else {
			setTableData([...tableData, formData]);
			onClose();
		}
	};

	const updateContactPerson = () => {
		if (vendorId) {
			const contactPersonId = tableData[selectedContactPersonIndex]?.contact_person_id;
			dispatch(updateVendorContactPerson(vendorId, contactPersonId, formData))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						reloadTable();
					} else {
						toast.error(res?.message || 'Something went wrong');
					}
				})
				.catch((err) => {});
		}

		const newTableData = tableData.map((data, i) =>
			i === selectedContactPersonIndex ? { ...data, ...formData } : { ...data },
		);

		setTableData(newTableData);
		onClose();
	};

	return (
		<div className="add-contact-person">
			<div className="row">
				<div className="col">
					<InputSelect
						options={ContactSolutation || []}
						label="Salutation"
						placeholder="Salutation"
						className="vendorContact-salutation"
						onChange={(value) => handleInputChange('salutation', value)}
						value={formData.salutation}
						disabled={disabled}
						required={true}
						error={error?.salutation}
						autoFocus={true}
					/>
				</div>
				<div className="col">
					<InputField
						label="First Name"
						placeholder="First Name"
						type="text"
						value={formData.first_name}
						onChange={(value) => handleInputChange('first_name', value)}
						disabled={disabled}
						error={error?.first_name}
					/>
				</div>
				<div className="col">
					<InputField
						label="Last Name"
						placeholder="Last Name"
						type="text"
						value={formData.last_name}
						onChange={(value) => handleInputChange('last_name', value)}
						disabled={disabled}
						error={error?.last_name}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col">
					<InputField
						label="Email"
						placeholder="Email"
						type="text"
						value={formData.email}
						onChange={(value) => handleInputChange('email', value)}
						disabled={disabled}
						error={error?.email}
					/>
				</div>
				<div className="col">
					<InputField
						label="Phone(Work)"
						placeholder="Phone No."
						type="text"
						value={formData.work_phone}
						onChange={(value) => handleInputChange('work_phone', value)}
						disabled={disabled}
						error={error?.work_phone}
					/>
				</div>
				<div className="col">
					<InputField
						label="Phone(Mobile)"
						placeholder="Phone no."
						type="text"
						value={formData.mobile}
						onChange={(value) => handleInputChange('mobile', value)}
						disabled={disabled}
						error={error?.mobile}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<InputField
						label="Designation"
						placeholder="Designation"
						type="text"
						value={formData.department}
						onChange={(value) => handleInputChange('department', value)}
						disabled={disabled}
						error={error?.department}
					/>
				</div>

				<div className="col">
					{/* <InputField
            label="Department"
            placeholder="Department"
            type="text"
            value={formData.department}
            onChange={(value) => handleInputChange("department", value)}
            disabled={disabled}
            error={error?.department}
          /> */}
				</div>

				<div className="col"></div>
			</div>

			<span className="">
				<Button
					type="button"
					className="btn-primary px-5 w-auto "
					button={selectedContactPersonIndex !== null ? 'Edit' : 'Add'}
					onClick={() => {
						if (selectedContactPersonIndex !== null) {
							updateContactPerson();
						} else {
							addContactPerson();
						}
					}}
				></Button>
			</span>
		</div>
	);
};

export default AddContactPerson;
