import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import OtherDetails from './OtherDetails';
import NavigationTab from './NavigationTab';
import VendorsAddress from './VendorsAddress';
import ContactPerson from './ContactPerson';
import BankDetails from './BankDetails';
import Remark from './Remark';
import UploadDoc from './VenUploadDocument';
import { createVendor, updateVendor, vendor_list } from '../../store/action/vendor';
import PageLoader from '../../component/PageLoader';
import { formatErrors } from '../../utils/controller';
import { toast } from 'react-toastify';
import {
	getDocName,
	isAllValuesEmpty,
	validateGSTNumber,
	validatePANCard,
	validDrugLicenseNumber,
} from '../../utils/utility';
import { master_data } from '../../store/action/master';

const CreateVendors = () => {
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState({});
	const [disabled, setDisabled] = useState(id ? true : false); // Add state for disabling inputs
	const [pageLoader, setPageLoader] = useState(false);
	const isVendorDisplayNameTouched = useRef(false);

	const [activeTab, setActiveTab] = useState('Other Details');

	function checkTabsData(obj) {
		return Object.values(obj).every((value) => value !== '');
	}
	// Table Data States
	const [vendorContact, setVendorContact] = useState([]);
	const [vendorBasicData, setVendorBasicData] = useState({
		vendor_name: '',
		vendor_display_name: '',
		drug_license_number: '',
		email: '',
		phone_landline: '',
		phone_mobile: '',
	});
	const [otherDetailsData, setOtherDetailsData] = useState({
		pan: '',
		gst: '',
		currency: 'INR',
		payment_terms: '',
		website_url: '',
		designation: '',
	});
	const [address, setAddress] = useState({
		attention: '',
		country_id: '',
		state_id: '',
		street_1: '',
		street_2: '',
		city: '',
		zip_code: '',
	});
	const [bankDetails, setBankDetails] = useState([]);
	// docs
	const [fssaiDoc, setFassaiDoc] = useState(null);
	const [gstDoc, setGstDoc] = useState(null);
	const [drugLicanceDocs, setDrugLicanceDocs] = useState(null);
	const [panDocs, setPanDocs] = useState(null);
	const [adharDocs, setAdharDocs] = useState(null);
	const [remark, setRemark] = useState('');
	const [vendorDocumentId, setVendorDocumentId] = useState('');

	const vendoralldata = useSelector(
		(state) => state?.vendorReducer?.vendorList?.data?.vendors?.[0],
	);

	useEffect(() => {
		getProductById();
	}, [id]);

	console.log('vendoralldata', vendoralldata);

	useEffect(() => {
		if (!id && vendoralldata?.vendor_id !== Number(id)) return;

		let otherDetails = vendoralldata?.vendor_other_details;
		let address = vendoralldata?.vendor_address;
		let vendorDocuments = vendoralldata?.vendor_documents;

		if (vendorDocuments) {
			setVendorDocumentId(vendorDocuments?.vendor_document_id);
			setGstDoc({ name: getDocName(vendorDocuments?.gst_certificate) });
			setDrugLicanceDocs({ name: getDocName(vendorDocuments?.drug_license) });
			setAdharDocs({ name: getDocName(vendorDocuments?.aadhar_card) });
			setPanDocs({ name: getDocName(vendorDocuments?.pan_card) });
			setFassaiDoc({ name: getDocName(vendorDocuments?.fssai_license) });
		}

		let vendorRemarks = vendoralldata?.vendor_remarks;

		setVendorContact(vendoralldata?.contact_person);
		setBankDetails(vendoralldata?.vendor_bank_details);
		setVendorBasicData({
			vendor_name: vendoralldata?.vendor_name ? vendoralldata?.vendor_name : '',
			vendor_display_name: vendoralldata?.vendor_display_name
				? vendoralldata?.vendor_display_name
				: '',
			drug_license_number: vendoralldata?.drug_license_number
				? vendoralldata?.drug_license_number
				: '',
			email: vendoralldata?.email ? vendoralldata?.email : '',
			phone_landline: vendoralldata?.phone_landline ? vendoralldata?.phone_landline : '',
			phone_mobile: vendoralldata?.phone_mobile ? vendoralldata?.phone_mobile : '',
		});
		setAddress({
			address_id: address?.address_id ? address?.address_id : '',
			attention: address?.attention ? address?.attention : '',
			country_id: address?.country_id ? address?.country_id : '',
			state_id: address?.state_id ? address?.state_id : '',
			street_1: address?.street_1 ? address?.street_1 : '',
			street_2: address?.street_2 ? address?.street_2 : '',
			city: address?.city ? address?.city : '',
			zip_code: address?.zip_code ? address?.zip_code : '',
		});
		setOtherDetailsData({
			vendor_other_details_id: otherDetails?.vendor_other_details_id
				? otherDetails?.vendor_other_details_id
				: '',
			pan: otherDetails?.pan ? otherDetails?.pan : '',
			gst: otherDetails?.gst ? otherDetails?.gst : '',
			currency: 'INR',
			payment_terms: otherDetails?.payment_terms ? otherDetails?.payment_terms : '',
			website_url: otherDetails?.website_url ? otherDetails?.website_url : '',
			designation: otherDetails?.designation ? otherDetails?.designation : '',
		});
		setRemark(vendorRemarks?.remark_text || '');
	}, [id, vendoralldata]);

	console.log('id', id);
	const getProductById = () => {
		if (id) {
			let param = `filterBy=vendor_id&filterValue=${id}`;
			dispatch(vendor_list(param));
		}
	};

	// master API call
	useEffect(() => {
		dispatch(master_data());
	}, [dispatch]);

	const navigateRouter = (path) => {
		navigate(path);
	};
	const handleBasicInputChange = (key, value) => {
		const updatedData = { ...vendorBasicData, [key]: value };
		setVendorBasicData(updatedData);

		if (!isVendorDisplayNameTouched.current && key === 'vendor_name') {
			setVendorBasicData({ ...updatedData, vendor_display_name: value });
		}

		if (key === 'drug_license_number') {
			if (value.length > 3) {
				if (!validDrugLicenseNumber(value)) {
					const newError = { ...error, [key]: 'Drug license is not valid' };
					setError(newError);
				} else {
					const newError = { ...error };
					delete newError?.[key];
					setError(newError);
				}
			}
		} else {
			if (value && error[key]) {
				if (key === 'vendor_name') {
					delete error?.vendor_display_name;
				}
				delete error?.[key];
			}
		}
	};

	const handleOtherDetailsInputChange = (key, value) => {
		const updatedData = { ...otherDetailsData, [key]: value };
		setOtherDetailsData(updatedData);

		if (key === 'pan') {
			if (value.length > 3) {
				if (!validatePANCard(value)) {
					const newError = { ...error, [key]: 'Pan number is not valid' };
					setError(newError);
				} else {
					const newError = { ...error };
					delete newError?.[key];
					setError(newError);
				}
			}
		} else if (key === 'gst') {
			if (value.length > 3) {
				if (!validateGSTNumber(value)) {
					const newError = { ...error, [key]: 'Gst number is not valid' };
					setError(newError);
				} else {
					const newError = { ...error };
					delete newError?.[key];
					setError(newError);
				}
			}
		} else {
			if (value && error[key]) {
				delete error?.[key];
			}
		}
	};

	const handleAddressInputChange = (key, value) => {
		const updatedData = { ...address, [key]: value };

		setAddress(updatedData);
		if (value && error[key]) {
			delete error?.[key];
		}
	};

	const submitForm = (e) => {
		e.preventDefault();
		if (Object.keys(error).length > 0) {
			return;
		}
		setPageLoader(true);
		const formData = new FormData();
		const { currency, ...rest } = otherDetailsData;
		if (!id) {
			const vendorObj = {
				...vendorBasicData,
				vendor_other_details: !isAllValuesEmpty(rest) ? JSON.stringify([otherDetailsData]) : '',
				address: !isAllValuesEmpty(address) ? JSON.stringify([address]) : '',
				contact_person: vendorContact?.length > 0 ? JSON.stringify(vendorContact) : '',
				bank_details: bankDetails?.length > 0 ? JSON.stringify(bankDetails) : '',
				remarks: remark ? remark : '',
			};

			for (const key in vendorObj) {
				formData.append(key, vendorObj[key]);
			}

			if (gstDoc) {
				formData.append('gst_certificate_doc', gstDoc);
			}
			if (drugLicanceDocs) {
				formData.append('drug_license_doc', drugLicanceDocs);
			}
			if (panDocs) {
				formData.append('pan_card_doc', panDocs);
			}
			if (adharDocs) {
				formData.append('aadhar_card_doc', adharDocs);
			}
			if (fssaiDoc) {
				formData.append('fssai_license_doc', fssaiDoc);
			}

			dispatch(createVendor(formData))
				.then((res) => {
					console.log('res in main compo', res);

					if (res?.success) {
						toast.success(res?.message);
						navigate(`/user/${suburl}/vendor`);
					} else {
						if (res?.code === 422) {
							let errorObj = formatErrors(res?.message);
							setError(errorObj);
						} else {
							toast.error(res?.message || 'Something went wrong');
						}
					}

					setPageLoader(false);
				})
				.catch((err) => {
					console.log('error in creating vendor', err);
					setError(err);
					setPageLoader(false);
				});
		}
		if (id) {
			console.log('checkTabsData===>', otherDetailsData, checkTabsData(otherDetailsData));
			const { currency, ...rest } = otherDetailsData;
			const updateVendorObj = {
				...vendorBasicData,
				vendor_other_details: !isAllValuesEmpty(rest) ? JSON.stringify([otherDetailsData]) : '',
				address: !isAllValuesEmpty(address) ? JSON.stringify([address]) : '',
				remarks: remark ? remark : '',
			};

			for (const key in updateVendorObj) {
				formData.append(key, updateVendorObj[key]);
			}

			dispatch(updateVendor(id, formData))
				.then((res) => {
					console.log('update vendor', res);
					setPageLoader(false);
					if (res?.success) {
						toast.success(res?.message);
						setDisabled(true);
					} else {
						if (res.code === 422) {
							let errorObj = formatErrors(res?.message);
							setError(errorObj);
						} else {
							toast.error(res?.message || 'Something went wrong');
						}
					}
				})
				.catch((err) => {
					console.log('error in creating vendor', err);
					setError(err);
					setPageLoader(false);
				});
		}
	};

	return (
		<>
			{pageLoader ? (
				<PageLoader />
			) : (
				<div className="">
					<div className="">
						<div className="product-pg-header add-product">
							{id ? <p>Vendor Details</p> : <p>Add New Vendor</p>}
							<div className="add-product-button">
								{id && disabled && (
									<Button
										type="button"
										className="btn-primary mt-0 px-4"
										button="Edit"
										onClick={() => setDisabled(false)}
									/>
								)}
								{disabled === false && (
									<Button
										type="button"
										className="btn-primary mt-0 px-4"
										button="Save"
										onClick={submitForm}
									/>
								)}
								{/* <Button type="button" className="btn-dark add-pro" button="Cancel" /> */}
								<Button
									type="button"
									className="mt-0 btn-danger add-pro"
									button=""
									close
									onClick={() => navigateRouter(`/user/${suburl}/vendor`)}
								/>
							</div>
						</div>
					</div>
					{/*  Vendor Form */}
					<div
						className="max-w-1297"
						style={{ padding: '1rem 2rem 0' }}
					>
						<div className="row">
							<div className="col">
								<InputField
									label="Vendor Name"
									placeholder="Vendor Name"
									type="text"
									required={true}
									value={vendorBasicData.vendor_name}
									onChange={(value) => handleBasicInputChange('vendor_name', value)}
									disabled={disabled}
									error={error?.vendor_name}
									autoFocus={true}
								/>
							</div>
							<div className="col">
								<InputField
									label="Vendor Display Name"
									placeholder="Vendor Display Name"
									type="text"
									onChange={(value) => handleBasicInputChange('vendor_display_name', value)}
									value={vendorBasicData.vendor_display_name}
									disabled={disabled}
									required={true}
									error={error?.vendor_display_name}
									onFocus={(e) => {
										isVendorDisplayNameTouched.current = true;
									}}
								/>
							</div>
							<div className="col">
								<InputField
									label="Drug License Number"
									placeholder="Drug License Number"
									type="text"
									required={true}
									value={vendorBasicData.drug_license_number}
									onChange={(value) => handleBasicInputChange('drug_license_number', value)}
									disabled={disabled}
									error={error?.drug_license_number}
									showValidateIcon={true}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<InputField
									label="Email"
									placeholder="Vendor Email"
									type="text"
									// required={true}
									value={vendorBasicData.email}
									onChange={(value) => handleBasicInputChange('email', value)}
									disabled={disabled}
									error={error?.email}
									// onBlur={() => {
									// 	if (!emailIsValid(vendorBasicData.email)) {
									// 		const newError = {
									// 			...error,
									// 			email: "Email is not valid",
									// 		};
									// 		setError(newError);
									// 	}
									// }}
								/>
							</div>

							<div className="col">
								<InputField
									label="Phone (Work)"
									placeholder="Vendor Phone (Work)"
									type="text"
									// required={true}
									value={vendorBasicData.phone_landline}
									onChange={(value) => handleBasicInputChange('phone_landline', value)}
									disabled={disabled}
									error={error?.phone_landline}
								/>
							</div>

							<div className="col">
								<InputField
									label="Phone (Mobile)"
									placeholder="Vendor Mobile (Work)"
									type="text"
									// required={true}
									value={vendorBasicData.phone_mobile}
									onChange={(value) => handleBasicInputChange('phone_mobile', value)}
									disabled={disabled}
									error={error?.phone_mobile}
								/>
							</div>
						</div>
						<NavigationTab
							activeTab={activeTab}
							setActiveTab={setActiveTab}
						/>
						{activeTab === 'Other Details' && (
							<OtherDetails
								data={otherDetailsData}
								handleInputChange={handleOtherDetailsInputChange}
								error={error}
								disabled={disabled}
								setError={setError}
							/>
						)}
						{activeTab === 'Address' && (
							<VendorsAddress
								data={address}
								error={error}
								handleInputChange={handleAddressInputChange}
								disabled={disabled}
							/>
						)}
						{activeTab === 'Contact person' && (
							<ContactPerson
								vendorContact={vendorContact}
								setVendorContact={setVendorContact}
								reloadTable={getProductById}
								vendorId={id}
							/>
						)}
						{activeTab === 'Bank Details' && (
							<BankDetails
								data={bankDetails}
								error={error}
								setBankDetails={setBankDetails}
								reloadTable={getProductById}
								vendorId={id}
							/>
						)}
						{activeTab === 'Upload Document' && (
							<UploadDoc
								vendorDocumentId={vendorDocumentId}
								setFassaiDoc={setFassaiDoc}
								setGstDoc={setGstDoc}
								setDrugLicanceDocs={setDrugLicanceDocs}
								setAdharCardDocs={setAdharDocs}
								setPanDocs={setPanDocs}
								fssaiDoc={fssaiDoc}
								gstDoc={gstDoc}
								drugLicanceDocs={drugLicanceDocs}
								adharDocs={adharDocs}
								panDocs={panDocs}
								reloadTable={getProductById}
								vendorId={id}
							/>
						)}
						{activeTab === 'Remarks' && (
							<Remark
								setRemark={setRemark}
								remark={remark}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default CreateVendors;
