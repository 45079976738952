import React from 'react';
import './venUploadDocument.scss';
import UploadDocuments from '../uploadDocument';
import ViewDocs from './ViewDocs';
const UploadDoc = ({
	vendorDocumentId,
	setFassaiDoc,
	setGstDoc,
	setDrugLicanceDocs,
	setAdharCardDocs,
	setPanDocs,
	fssaiDoc,
	gstDoc,
	drugLicanceDocs,
	adharDocs,
	panDocs,
	reloadTable,
}) => {
	return (
		<div className="vendor-upload-document">
			{/* UploadDoc Index */}
			<div
				className="accordion"
				id="accordionExample"
			>
				<div className="accordion-item">
					<h2
						className="accordion-header"
						id="headingOne"
					>
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
							aria-expanded="false"
							aria-controls="collapseOne"
						>
							<span>GST Certificate</span>
						</button>
					</h2>
					<div
						id="collapseOne"
						className="accordion-collapse collapse"
						aria-labelledby="headingOne"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<div className="document-area">
								{!gstDoc?.name && <p>No Document Uploaded</p>}
								<UploadDocuments
									name="gst_certificate_doc"
									vendorDocumentId={vendorDocumentId}
									setDocs={setGstDoc}
									docName={gstDoc?.name}
									reloadTable={reloadTable}
								/>
								<ViewDocs docName={gstDoc?.name} />
							</div>
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2
						className="accordion-header"
						id="headingTwo"
					>
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseTwo"
							aria-expanded="false"
							aria-controls="collapseTwo"
						>
							<span>Drug License</span>
						</button>
					</h2>
					<div
						id="collapseTwo"
						className="accordion-collapse collapse"
						aria-labelledby="headingTwo"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<div className="accordion-body">
								<div className="document-area">
									{!drugLicanceDocs?.name && <p>No Document Uploaded</p>}
									<UploadDocuments
										name="drug_license_doc"
										vendorDocumentId={vendorDocumentId}
										setDocs={setDrugLicanceDocs}
										docName={drugLicanceDocs?.name}
										reloadTable={reloadTable}
									/>
									<ViewDocs docName={drugLicanceDocs?.name} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2
						className="accordion-header"
						id="headingThree"
					>
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseThree"
							aria-expanded="false"
							aria-controls="collapseThree"
						>
							<span>Pan Card</span>
						</button>
					</h2>
					<div
						id="collapseThree"
						className="accordion-collapse collapse"
						aria-labelledby="headingThree"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<div className="document-area">
								{!panDocs?.name && <p>No Document Uploaded</p>}
								<UploadDocuments
									name="pan_card_doc"
									vendorDocumentId={vendorDocumentId}
									setDocs={setPanDocs}
									docName={panDocs?.name}
									reloadTable={reloadTable}
								/>
								<ViewDocs docName={panDocs?.name} />
							</div>
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2
						className="accordion-header"
						id="headingFour"
					>
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFour"
							aria-expanded="false"
							aria-controls="collapseFour"
						>
							<span>Aadhar Card</span>
						</button>
					</h2>
					<div
						id="collapseFour"
						className="accordion-collapse collapse"
						aria-labelledby="headingFour"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<div className="document-area">
								{!adharDocs?.name && <p>No Document Uploaded</p>}
								<UploadDocuments
									name="aadhar_card_doc"
									vendorDocumentId={vendorDocumentId}
									setDocs={setAdharCardDocs}
									docName={adharDocs?.name}
									reloadTable={reloadTable}
								/>
								<ViewDocs docName={adharDocs?.name} />
							</div>
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h2
						className="accordion-header"
						id="headingFive"
					>
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFive"
							aria-expanded="false"
							aria-controls="collapseFive"
						>
							<span>FSSAI Certificate</span>
						</button>
					</h2>
					<div
						id="collapseFive"
						className="accordion-collapse collapse"
						aria-labelledby="headingFive"
						data-bs-parent="#accordionExample"
					>
						<div className="accordion-body">
							<div className="document-area">
								{!fssaiDoc?.name && <p>No Document Uploaded</p>}
								<UploadDocuments
									name="fssai_license_doc"
									vendorDocumentId={vendorDocumentId}
									setDocs={setFassaiDoc}
									docName={fssaiDoc?.name}
									reloadTable={reloadTable}
								/>
								<ViewDocs docName={fssaiDoc?.name} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadDoc;
