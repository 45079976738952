import React, { useState, useEffect, useRef } from "react";
import "./InputTable.scss";
import InputSelect from "../inputSelect/InputSelect";
import { useDispatch } from "react-redux";
import { product_batch, product_list } from "../../store/action/product";
import { useLocation, useParams } from "react-router-dom";
import ErrorModal from "../ErrorModal/ErrorModal";

const PurchaseBillsTable = ({
  initialRows,
  columns,
  className,
  onChange,
  value,
  disabled,
  newRowgenerate = "",
  returnInputvalue,
  tableError,
  setIsTableErrorShow,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [batchOptions, setBatchOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [rows, setRows] = useState([]);
  const [errorCells, setErrorCells] = useState([]);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const param = useParams();
  const paramId = param.id;
  const [loading, setLoading] = useState(false);

  // Access query parameters
  const paramProductID = queryParams.get("prodid");
  const paramProductName = queryParams.get("prod");

  // Fetch product list and details
  useEffect(() => {
    let param = `page=1&pageSize=2000`;
    fetchProduct(param);
  }, []);

  useEffect(() => {
    if (errorCells.length > 0) {
      setIsTableErrorShow(true);
    } else {
      setIsTableErrorShow(false);
    }
  }, [errorCells]);
  useEffect(() => {
    if (
      tableError ||
      (tableError && rows?.length > 0 && errorCells.length > 0)
    ) {
      const secondLastRowIndex = rows.length - 1;
      const secondLastRow = rows[secondLastRowIndex];

      // Determine if any cell in the second last row is empty or has a placeholder value
      const errors = columns
        .filter((col) => {
          const value = secondLastRow[col.name];
          // Check if the value is empty or a placeholder that should be considered an error
          return (
            (value === null || value === "") &&
            [
              "product_id",
              "batch_id",
              // "qty",
              "quantity_adjusted",
              // "qtyHand",
            ].includes(col.name)
          );
        })
        .map((col) => ({
          index: secondLastRowIndex,
          name: col.name,
        }));
      // Update errorCells state based on the errors found
      setErrorCells((prevErrorCells) => [
        ...prevErrorCells.filter(
          (error) => !(error.index === secondLastRowIndex)
        ),
        ...errors,
      ]);
    }
  }, [tableError]);
  useEffect(() => {
    // setRows(
    //   value.length > 0 ? value : initialRows.length > 0 ? initialRows : []
    // );
    if (paramId && !paramProductID) {
      setLoading(true);
      if (value?.length > 0) {
        setRows(value);
        setLoading(false);
      }
    } else {
      if (!paramProductID) {
        setLoading(true);
        setRows(initialRows);
        setLoading(false);
      }
    }
  }, [paramId, paramId ? value : ""]);

  useEffect(() => {
    if (paramProductID) {
      fetchProduct();
      getProductBatchByProductId(paramProductID, 0);
      // setLoading(false);
      let initValue = [
        {
          product_id: paramProductName,
          batch_id: "",
          quantity_adjusted: "",
          // adjustment_details_id: "",
          product_name:Number(paramProductID),
          qty: "",
          qtyHand: "",
        },
      ];
      setRows(initValue);

      // setLoading(false);
    }
  }, [paramProductID]);

  const getProductBatchByProductId = (productID, index) => {
    let param = `filterBy=product_id&filterValue=${productID ? productID : ""}`;
    dispatch(product_list(param)).then((res) => {
      if (res?.success) {
        let filteredProduct = res?.data?.productWithStockCount?.[0];
        let batch = res?.data?.productWithStockCount?.[0]?.batchdetails?.map(
          (ele) => ({
            key: ele.product_batch_id,
            value: ele.batch_no,
          })
        );
        setBatchOptions(batch || []);
        setSelectedRowData((prevData) => ({
          ...prevData,
          [index]: filteredProduct,
        }));
        // if (!paramProductID || !paramId) {
        //   setRows([]);
        // }
      } else {
        console.log(res);
      }
    });
  };

  const fetchProduct = (param) => {
    dispatch(product_list(param)).then((res) => {
      if (res?.success) {
        setProductDetails(res?.data?.productWithStockCount);
        const productOptions = res?.data?.productWithStockCount?.map((ele) => ({
          key: ele?.product_id,
          value: ele?.product_name,
        }));
        setProductOptions(productOptions);
      } else {
      }
    });
  };

  // Debounce function definition
  const getproductbyInoutValue = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  // Create a debounced version of fetchProduct
  const debouncedFetchProduct = getproductbyInoutValue((query) => {
    fetchProduct(query);
  }, 300); // Adjust the delay as needed

  // Helper function to get product details by product_id
  const getProductById = (productId) => {
    return productDetails?.find((product) => product.product_id == productId);
  };

  // Add a new row
  const addRow = () => {
    const emptyRow = columns.reduce((acc, col) => {
      acc[col.name] = "";
      return acc;
    }, {});
    const newRows = [...rows, emptyRow];
    setRows(newRows);
    // Check if the second last row is empty
    if (newRows.length > 1) {
      const secondLastRowIndex = newRows.length - 2;
      const secondLastRow = newRows[secondLastRowIndex];

      // Determine if any cell in the second last row is empty or has a placeholder value
      const errors = columns
        .filter((col) => {
          const value = secondLastRow[col.name];
          // Check if the value is empty or a placeholder that should be considered an error
          return (
            (value === null || value === "") &&
            [
              "product_id",
              "batch_id",
              // "qty",
              "quantity_adjusted",
              // "qtyHand",
            ].includes(col.name)
          );
        })
        .map((col) => ({
          index: secondLastRowIndex,
          name: col.name,
        }));
      // Update errorCells state based on the errors found
      setErrorCells((prevErrorCells) => [
        ...prevErrorCells.filter(
          (error) => !(error.index === secondLastRowIndex)
        ),
        ...errors,
      ]);
    }

    if (onChange) {
      onChange(newRows);
    }
    setTimeout(() => {
      inputRefs.current[newRows.length - 1]?.[0]?.focus();
      let lastRowRef = inputRefs.current[newRows.length - 1]?.[0];
      lastRowRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 0);
  };

  // Delete a row
  const deleteRow = (index) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      const newErrorCells = errorCells
        .filter((error) => error.index !== index)
        .map((error) => ({
          ...error,
          index: error.index > index ? error.index - 1 : error.index,
        }));
      setErrorCells(newErrorCells);
      setRows(newRows);
      if (onChange) {
        onChange(newRows);
      }
      setTimeout(() => {
        inputRefs.current[newRows.length - 1]?.[0]?.focus();
      }, 0);
    } else {
      // setRows([]);
      // setErrorCells([]);
      // setRows(initialRows);
    }
  };

  // Handle input change in the table
  const handleChange = (index, event) => {
    let { name, value } = event.target;
    value = value?.replace(/[^0-9.]/g, "");
    const decimalCount = (value.match(/\./g) || []).length;
    let stockInhand = 0;
    if (decimalCount > 1) {
      value = +value?.slice(0, value.lastIndexOf("."));
    }

    stockInhand = rows[index]?.qty - value;

    if (rows[index]?.qty && +value <= rows[index]?.qty) {
      setErrorCells(
        errorCells.filter(
          (cell) => !(cell.index === index && cell.name === name)
        )
      );
    } else {
      setErrorCells([...errorCells, { index, name }]);
    }
    const newRows = [...rows];
    newRows[index].qtyHand = stockInhand === 0 ? "" : stockInhand;
    newRows[index][name] = value;
    setRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };
  // Handle select input change (product_id)
  const handleProductInputChange = (index, value) => {
    if (rows.length === 1 && value && isNaN(rows?.product_id)) {
      getProductBatchByProductId(paramProductID, index);
    }
    const newRows = [...rows];
    let param = `filterBy=product_name&filterValue=${value}`;
    debouncedFetchProduct(param);
    const filteredProduct = getProductById(value);
    setSelectedRowData((prevData) => ({
      ...prevData,
      [index]: filteredProduct,
    }));
    const batch = filteredProduct?.batchdetails?.map((ele) => ({
      key: ele.product_batch_id,
      value: ele.batch_no,
    }));
    setBatchOptions(batch || []);
    if (value === "") {
      setErrorCells([...errorCells, { index, name: "product_id" }]);
    } else {
      setErrorCells(
        errorCells.filter(
          (cell) => !(cell.index === index && cell.name === "product_id")
        )
      );
    }

    newRows[index].product_id = value;
    // newRows[index].product_name = filteredProduct?.product_name || "";
    setRows(newRows);
    if (onChange) {
      onChange(newRows);
    }
  };

  // Handle batch change
  const handleBatchChange = (index, value, indexName) => {
    let name = indexName;
    let ProductCode = isNaN(rows[index]?.product_name)
      ? rows[index]?.product_id
      : rows[index]?.product_name;
    let batchCode = isNaN(rows[index]?.batch_no)
      ? rows[index]?.batch_id
      : rows[index]?.batch_no;

    if (paramId) {
      setBatchOptions([]);
      getProductBatchByProductId(ProductCode, index);
    }

    if (value) {
      let updatedErrorCell = errorCells.filter(
        (cell) => !(cell.index === index && cell.name === "batch_id")
      );
      setErrorCells(updatedErrorCell);
      const filteredBatch = selectedRowData[index]?.batchdetails?.find(
        (ele) => (ele.product_batch_id || ele.batch_no) === value
      );

      let filterProductselectedIndex = productDetails.filter((ele) => {
        return ele?.product_id === ProductCode;
      });

      let batch_qty = filterProductselectedIndex?.[0]?.batchdetails?.filter(
        (item) => {
          return item?.product_batch_id === batchCode;
        }
      );

      const availableQuantity =
        filteredBatch?.free_quantity + filteredBatch?.quantity ||
        batch_qty?.[0]?.quantity ||
        0;

      const newRows = [...rows];
      newRows[index][name] = value;
      newRows[index].qty = availableQuantity;
      setRows(newRows);
      if (onChange) {
        onChange(newRows);
      }
    } else {
      setErrorCells([...errorCells, { index, name: "batch_id" }]);
    }
  };

  // Handle key down events (e.g., adding a new row)
  const handleKeyDown = (index, event) => {
    let { name, value } = event.target;
    if (event.key === "Enter") {
      const column = columns.find((col) => col.name === event.target.name);
      if (
        (column && column.label === newRowgenerate) ||
        (column && column.label === "Quantity In Hand")
      ) {
        addRow();
      }
    } else {
      if (name === "quantity_adjusted") {
        handleChange(index, event);
      }
    }
  };

  // Handle reference for input elements
  const handleRef = (rowIndex, cellIndex) => (el) => {
    if (!inputRefs.current[rowIndex]) {
      inputRefs.current[rowIndex] = [];
    }
    inputRefs.current[rowIndex][cellIndex] = el;
  };

  const getToolTipData = (name) => {
    switch (name) {
      case "product_id":
        return "Name can not be empty.";
      case "batch_id":
        return "Batch No can not be empty";
      case "quantity_adjusted":
        return `Quantity Adjusted can not be greater than Quantity Available`;
      default:
        return "";
    }
  };

  // New useEffect to handle changes in the 'disabled' prop
  useEffect(() => {
    if (rows.length > 0 && onChange) {
      onChange(rows);
    }
  }, [disabled]);

  const ErrorModalOpen = () => {
    setShowError(true);
  };
  const CloseErrorModal = () => {
    setShowError(false);
    setTimeout(() => {
      if (rows.length > 0) {
        inputRefs.current[rows.length - 1]?.[0]?.focus();
      }
    }, 0);
  };

  return (
    <>
      {errorCells && errorCells?.length > 0 && (
        <div className="d-flex justify-content-end  text-danger  mb-0">
          {" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={ErrorModalOpen}
          >
            Show all error
          </span>
        </div>
      )}
      <div className="text-center mb-2 custum_table">
        <div>
          <table>
            <thead>
              <tr>
                {columns.map((col) => (
                  <th className={`${className}`} key={col.name}>
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "start",
                        alignItems: "center",
                      }}
                    >
                      <span>{col.label}</span>
                      <span style={{ color: "red" }}>
                        {[
                          "product_id",
                          "batch_id",
                          "qty",
                          "quantity_adjusted",
                          "qtyHand",
                        ].includes(col.name)
                          ? "*"
                          : ""}
                      </span>
                      {["product_id", "batch_id", "quantity_adjusted"].includes(
                        col.name
                      ) ? (
                        <i className="toolTip_custum fa fa-info-circle">
                          <span className="toolTip_custumtext">
                            {getToolTipData(col.name)}
                          </span>
                        </i>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  marginTop: "5%",
                  marginLeft: "40%",
                  position: "fixed ",
                }}
              >
                <span
                  className="Page_loader"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            ) : (
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((col, cellIndex) => (
                      <td
                        key={col.name}
                        className={
                          errorCells.some(
                            (cell) =>
                              cell.index === rowIndex && cell.name === col.name
                          )
                            ? "error"
                            : ""
                        }
                      >
                        {col.type === "batch_no" ? (
                          <InputSelect
                            placeholder="Select Batch No"
                            value={
                              batchOptions?.find(
                                (option) => option.key === row[col.name]
                              )?.value || row[col.name]
                            }
                            onChange={(value) =>
                              handleBatchChange(rowIndex, value, col?.name)
                            }
                            propKeydown={(e) =>
                              handleBatchChange(
                                rowIndex,
                                e.target.value,
                                col?.name
                              )
                            }
                            options={batchOptions}
                            returnInputValue={returnInputvalue}
                            disabled={disabled}
                            propInputRef={handleRef(rowIndex, cellIndex)}
                            isLocalValidationError={
                              errorCells.some(
                                (cell) =>
                                  cell.index === rowIndex &&
                                  cell.name === col.name
                              )
                                ? true
                                : false
                            }
                            onfucusCapture={(e) =>
                              handleBatchChange(
                                rowIndex,
                                e.target.value,
                                col?.name
                              )
                            }
                          />
                        ) : col.type === "product_name" ? (
                          <InputSelect
                            placeholder="Select Product"
                            value={
                              productOptions?.find(
                                (option) => option.key === row[col.name]
                              )?.value || row[col.name]
                            }
                            onChange={(value) =>
                              handleProductInputChange(rowIndex, value)
                            }
                            propKeydown={(e) =>
                              handleProductInputChange(rowIndex, e.target.value)
                            }
                            isLocalValidationError={
                              errorCells.some(
                                (cell) =>
                                  cell.index === rowIndex &&
                                  cell.name === col.name
                              )
                                ? true
                                : false
                            }
                            options={productOptions}
                            disabled={disabled}
                            returnInputValue={true}
                            propInputRef={handleRef(rowIndex, cellIndex)}
                            onfucusCapture={(e) =>
                              handleProductInputChange(rowIndex, e.target.value)
                            }
                          />
                        ) : (
                          <input
                            className="table_input"
                            type={col.type}
                            name={col.name}
                            value={row[col.name]}
                            style={{
                              border: "1px solid rgba(85, 91, 103, 0.15)",
                              borderRadius: "8px",
                            }}
                            onChange={(e) => handleChange(rowIndex, e)}
                            onKeyDown={(e) => handleKeyDown(rowIndex, e)}
                            disabled={
                              disabled
                                ? disabled
                                : col.name === "qtyHand" || col.name === "qty"
                                ? true
                                : false
                            }
                            // disabled={disabled}
                            ref={handleRef(rowIndex, cellIndex)}
                          />
                        )}
                      </td>
                    ))}
                    <td>
                      {console.log(rows?.adjustment_details_id)}
                      {!((row?.adjustment_details_id || rowIndex === 0 || disabled)) && (
                        <button
                          className="delete mt-0 bg-transparent"
                          onClick={() => deleteRow(rowIndex)}
                          tabIndex="-1"
                          disabled = {disabled}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M3 6H5H21"
                              stroke="#FF0000"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                              stroke="#FF0000"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 11V17"
                              stroke="#FF0000"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14 11V17"
                              stroke="#FF0000"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {!disabled && (
          <button
            className="bg-transparent text-dark add-new-row-button mt-2"
            onClick={addRow}
          >
            Add New Row
            <i
              className="fa fa-plus"
              style={{
                marginLeft: ".5rem",
                strokeWidth: ".1rem",
                color: "var(--brand-color)",
                WebkitTextStrokeColor: "#fff",
                WebkitTextStrokeWidth: "1px",
              }}
            />
          </button>
        )}
      </div>
      <div className="text-start">
        {showError && (
          <ErrorModal
            show={showError}
            isOpen={showError}
            onClose={CloseErrorModal}
            title="Error in Add Inventory Table"
            error={errorCells}
          />
        )}
      </div>
    </>
  );
};

export default PurchaseBillsTable;
