import '../Vendors.scss';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import ReadOnlyTable from '../../../component/InputTable/ReadOnlyTable';
import { deleteVendorContactPerson } from '../../../store/action/vendor';

const ContactPersonTable = ({ tableData, vendorId, setTableData, className, handleModalOpen }) => {
	const dispatch = useDispatch();

	function deleteRow(index) {
		const contactPersonId = tableData?.[index]?.contact_person_id;
		if (vendorId && contactPersonId) {
			dispatch(deleteVendorContactPerson(vendorId, contactPersonId))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
					} else {
						toast.error('Error in deleting contact person');
					}
				})
				.catch((err) => {
					toast.error(err?.message || 'Error in deleting contact person');
				});
		}

		const updatedTableData = tableData.filter((_, i) => i !== index);
		setTableData(updatedTableData);
	}

	const columns = [
		{ name: 'salutation', label: 'Salutation', type: 'text' },
		{ name: 'first_name', label: 'First Name', type: 'text' },
		{ name: 'last_name', label: 'Last Name', type: 'text' },
		{ name: 'mobile', label: 'Mobile', type: 'text' },
		{ name: 'work_phone', label: 'Work Phone', type: 'text' },
		{ name: 'email', label: 'Email', type: 'text' },
	];

	const initialRows = [
		{
			salutation: '',
			first_name: '',
			last_name: '',
			mobile: '',
			work_phone: '',
			email: '',
		},
	];
	return (
		<div
			className={`add-vendor-contactTable add-product-inputTable ${className}`}
			style={{ marginTop: '0' }}
		>
			<ReadOnlyTable
				className="th-width"
				initialRows={initialRows}
				columns={columns}
				value={tableData}
				disabled={true}
				deleteRow={deleteRow}
				handleModalOpen={handleModalOpen}
			/>
		</div>
	);
};

export default ContactPersonTable;
