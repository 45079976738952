import React from "react";
import PurchaseBillsTable from "../../component/InputTable/PurchaseBillsTable";

const BillTable = ({
  tableData,
  setTableData,
  disabled,
  paramId,
  tableError,
  setIsTableErrorShow,
}) => {
  const handleTableChange = (newData) => {
    setTableData(newData);
    // console.log('new Data', newData)
  };

  const columns = [
    { name: "product_id", label: "Product Name", type: "product_name" },
    { name: 'batch_no', label: 'Batch No', type: 'batch_no' },
    { name: 'quantity', label: 'Qty', type: 'text' },
    { name: 'free_quantity', label: 'Free Qty', type: 'text' },
    { name: 'mfg_date', label: 'MFG Date', type: 'date' },
    { name: 'expiry_date', label: 'EXP Date', type: 'date' },
    { name: 'mrp', label: 'MRP', type: 'text' },
    // { name: 'trade_rate', label: 'Trade Rate', type: 'text' },
    { name: 'purchase_rate', label: 'Purchase Rate', type: 'text' },
    { name: 'sale_rate', label: 'Sale Rate', type: 'text' },
  ];

  const initialRows = [
    {    product_id: "", batch_no: '', quantity: '0', free_quantity: '0', mfg_date: '', expiry_date: '', mrp: '0.00', trade_rate: "0.00", purchase_rate: '0.00', sale_rate: '0.00', product_batch_id: "" },
  ];
  // console.log('tableData', tableData)
  return (
    <div
      className=" max-w-1297"
      style={{ padding: "0rem 2rem" }}
    >
      {tableData && (
        <PurchaseBillsTable
          initialRows={initialRows}
          columns={columns}
          onChange={handleTableChange}
          value={tableData}
          disabled={disabled}
          newRowgenerate={"Quantity Adjusted"}
          returnInputvalue={false}
          paramId={paramId}
          tableError={tableError}
          setIsTableErrorShow={setIsTableErrorShow}
        />
      )}
    </div>
  );
};

export default BillTable;
