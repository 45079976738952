import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import './uploadDocument.scss';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { reuploadVendorDocument, uploadVendorDocument } from '../../../store/action/vendor';

const UploadDocuments = ({ vendorDocumentId, name, setDocs, docName, reloadTable }) => {
	// console.log('setDocs',setDocs)
	const [files, setFiles] = useState([]);
	const inputRef = useRef();
	const param = useParams();
	const dispatch = useDispatch();

	const vendorId = param?.id;

	const validFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

	const handleFileValidation = (fileList) => {
		const validFiles = [];
		for (let i = 0; i < fileList.length; i++) {
			const file = fileList[i];
			if (validFileTypes.includes(file.type)) {
				validFiles.push(file);
			} else {
				toast(`Invalid file type: ${file.name}`, 'error');
			}
		}
		return validFiles;
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		const validatedFiles = handleFileValidation(droppedFiles);
		setFiles(validatedFiles);
		setDocs(validatedFiles[0]);

		if (name) {
			reuploadDocument(validatedFiles[0]);
		}
	};

	const handleFileChange = (event) => {
		const selectedFiles = event.target.files;
		const validatedFiles = handleFileValidation(selectedFiles);
		console.log('validatedFiles2', validatedFiles);
		setFiles(validatedFiles);
		setDocs(validatedFiles[0]);
		if (name) {
			reuploadDocument(validatedFiles[0]);
		}
	};

	const reuploadDocument = (file) => {
		const form = new FormData();

		form.append(name, file);

		if (vendorDocumentId) {
			dispatch(reuploadVendorDocument(vendorDocumentId, form))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						reloadTable();
					} else {
						toast.error(res?.message || 'Something went wrong');
					}
				})
				.catch((error) => {
					toast.error(error?.message || 'Something went wrong');
				});
		} else if (vendorId) {
			dispatch(uploadVendorDocument(vendorId, form))
				.then((res) => {
					if (res?.success) {
						toast.success(res?.message);
						reloadTable();
					} else {
						toast.error(res?.message || 'Something went wrong');
					}
				})
				.catch((error) => {
					toast.error(error?.message || 'Something went wrong');
				});
		}
	};

	return (
		<div className="image-upload-component">
			<div
				className=""
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>
				<input
					type="file"
					multiple
					onChange={handleFileChange}
					hidden
					accept="image/png, image/jpeg, application/pdf"
					ref={inputRef}
				/>
				<button
					className="btn btn-lg btn-primary font-14 mt-0 px-4"
					onClick={() => inputRef.current.click()}
				>
					{docName ? 'Re-Upload Document' : 'Upload Document'}
				</button>
			</div>
		</div>
	);
};

export default UploadDocuments;
