import React from 'react'
import './Purchase.scss'
const PurchaseDetails = () => {
  return (
    <div>
      <div className='invoice-head'>
        <div className='invoice-status'>
          <div className='received-s'>
            Received Status:
            <span>Received</span>
          </div>
          <span>|</span>
          <div className='bill-s'>
            Bill Status:
            <span>Pending</span>
          </div>
        </div>

        <div className='status-toggle'>
          Show PDF View
          <span>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />

            </div></span>
        </div>
      </div>

      <div class="invoice">

        <div class="header">
          <div>
            <h1>Medical Store</h1>
            <p>
              <div className='state'>Rajasthan</div>
              <div className='country'>India</div>
              <div className='email-id'>aditbhargava.devoy@gmail.com</div>
            </p>

          </div>

          <div class="bill-info">
            <h2>Purchase order</h2>
            <p>PO-0001231</p>
          </div>
        </div>

        {/* <div class="details">
          <p><strong>Bill Date:</strong> INV-000010</p>
          <p><strong>Due Date:</strong> 19/03/2024</p>
          <p><strong>Terms:</strong> Net 0</p>
          <p><strong>Balance Due:</strong> Rs. 0.00</p>
        </div> */}

        <div class="address-to">
          <p className='label'>Vendor Address</p>
          <p className='address'>ABC Company Private Limited</p>
        </div>
        <div className='delivey-details'>
          <div class="deliver-to">
            <p className='label'>Deliver To</p>
            <p className='address'>103/3 Kumbha Marg Pratap Nagar Sanganare
              Jaipur 302033 Rajasthan India</p>
          </div>
          <div class="date-to">
            <p className='label'>Date:</p>
            <span className='date'>10/10/2024</span>
          </div>
        </div>
        <table class="items">
          <thead>
            <tr>
              <th>#</th>
              <th>Item & Description</th>
              <th>Qnty</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Product A</td>
              <td>1.00 ml</td>
              <td>600.00</td>
              <td>600.00</td>
            </tr>
          </tbody>
        </table>

        <div class="summary">
          <p className='subtotal'>Sub Total <span >600.00</span></p>
          {/* <p>Total: <span>600.00</span></p>
          <p class="payment">Payment Made: <span>(-)320</span></p> */}
          <p class="balance">Total: <span>Rs. 600.00</span></p>
        </div>

        <div class="signature">
          <p>Authorized Signature--------------------</p>
        </div>
      </div>
    </div>
  )
}

export default PurchaseDetails