import React, { useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';

const AddPaymentTerms = ({ value, handelPaymentTermsInputChange, handleSaveClick, onCLose }) => {
	const [error, setError] = useState('');

	const [paymentTerms, setPaymentTerms] = useState('');

	return (
		<div>
			<form className="add-productgroup-form">
				<InputField
					label="Payment Terms"
					placeholder=""
					type="text"
					name="Enter payment terms"
					autoFocus={true}
					value={paymentTerms}
					onChange={(val) => setPaymentTerms(val)}
					error={error?.addNewPaymentTerms || error}
				/>
				<span className="">
					<Button
						type="button"
						className="btn-primary mt-4 px-5 w-auto "
						button="Done"
						onClick={() => handleSaveClick(paymentTerms)}
					></Button>
				</span>
			</form>
		</div>
	);
};

export default AddPaymentTerms;
