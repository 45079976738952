import React, { useState } from 'react';
import bankIcon from '../../assets/image/bankIcon.svg';
import bankEdit from '../../assets/image/edit-2.svg';

const BankCard = ({ id, data, openEditBankDetailsModal }) => {
	const [hideAccountNumber, setHideAccountNumber] = useState(false);

	const starStr = data?.account_number;

	const starStrLength = starStr.slice(0, starStr.length - 4)?.length;

	return (
		<div
			style={{
				backgroundColor: 'rgba(229, 230, 232, 1)',
				borderRadius: '5px',
				padding: '1.5rem 2rem',
				margin: '5px',
			}}
		>
			<div className="accDetails">
				<div className="accIcon">
					<img
						src={bankIcon}
						alt=""
					/>
				</div>
				<div className="accName">
					<div>{data?.bank_name}</div>
					<div>
						{!hideAccountNumber ? (
							<>
								{new Array(starStrLength + 1).join('*')}
								{starStr.slice(-4)}
							</>
						) : (
							<>{starStr}</>
						)}

						<span
							className="numberView"
							onClick={() => {
								setHideAccountNumber((prev) => !prev);
							}}
						>
							{!hideAccountNumber ? 'View' : 'Hide'}
						</span>
					</div>
				</div>
				<div
					className="accEdit"
					onClick={() => {
						openEditBankDetailsModal(id);
					}}
				>
					<img
						src={bankEdit}
						alt=""
					/>
				</div>
			</div>

			<div className="codeName">
				<span>IFSC Code</span>
				<span>: {data?.ifsc_code}</span>
			</div>
			<div className="codeName">
				<span>Account Holder Name</span>
				<span>: {data?.beneficiary_name}</span>
			</div>
		</div>
	);
};

export default BankCard;
