import React, { useState, useEffect, useRef } from 'react';

import './InputTable.scss';
import { useParams } from 'react-router-dom';

const ReadOnlyTable = ({
	initialRows = [],
	columns = [],
	className = '',
	value = [],
	deleteRow,
	handleModalOpen,
}) => {
	const [rows, setRows] = useState([]);

	// Handle dynamic addition of refs

	useEffect(() => {
		setRows(value.length > 0 ? value : initialRows.length > 0 ? initialRows : []);
	}, [value, initialRows]);

	return (
		<>
			<div className="text-center mt-0">
				<div>
					<table>
						<thead>
							<tr>
								{columns.map((col) => (
									<th
										className={`${className}`}
										key={col.name}
									>
										<div
											style={{
												display: 'flex',
												justifyItems: 'start',
												alignItems: 'center',
											}}
										>
											<span>{col.label}</span>
										</div>
									</th>
								))}
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{rows.map((row, index) => (
								<tr key={index}>
									{columns.map((col, colIndex) => (
										<td key={col.name}>
											{col.type === 'text' && (
												<input
													type="text"
													name={col.name}
													value={row[col.name]}
													readOnly={true}
													className="table_input"
												/>
											)}
										</td>
									))}
									<td>
										<button
											className="edit mt-0 mb-0"
											onClick={() => handleModalOpen(index)}
										>
											Edit
										</button>
										<button
											className="delete mt-0 mb-0 bg-transparent"
											onClick={() => {
												deleteRow(index);
											}}
											tabIndex="-1"
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
											>
												<path
													d="M3 6H5H21"
													stroke="#FF0000"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
													stroke="#FF0000"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M10 11V17"
													stroke="#FF0000"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M14 11V17"
													stroke="#FF0000"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default ReadOnlyTable;
